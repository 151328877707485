import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { theme } from "../../theme";
import { normalizeThemeUIColors, filterStyles } from "@lekoarts/gatsby-theme-specimens";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Audio = makeShortcode("Audio");
const BorderRadius = makeShortcode("BorderRadius");
const ColorRow = makeShortcode("ColorRow");
const ColorSwatch = makeShortcode("ColorSwatch");
const Download = makeShortcode("Download");
const FontFamily = makeShortcode("FontFamily");
const FontSize = makeShortcode("FontSize");
const FontWeight = makeShortcode("FontWeight");
const Heading = makeShortcode("Heading");
const Palette = makeShortcode("Palette");
const Table = makeShortcode("Table");
const Shadow = makeShortcode("Shadow");
const Space = makeShortcode("Space");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "specimens-for-design-systems-preview"
    }}>{`Specimens for Design Systems (Preview)`}</h1>
    <p>{`Go back to the `}<a parentName="p" {...{
        "href": "/"
      }}><strong parentName="a">{`homepage`}</strong></a>{` to see installation and usage instructions of `}<a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-specimens"
      }}>{`@lekoarts/gatsby-theme-specimens`}</a>{`!`}</p>
    <h2 {...{
      "id": "alert"
    }}>{`Alert`}</h2>
    <Alert type="success" mdxType="Alert">Make it so!</Alert>
    <Alert type="hint" mdxType="Alert">Neutral Hint</Alert>
    <Alert type="warning" mdxType="Alert">Gentle warning :)</Alert>
    <Alert type="info" mdxType="Alert">Super helpful information goes here</Alert>
    <Alert type="danger" mdxType="Alert">nooooooooo, not this way</Alert>
    <h2 {...{
      "id": "audio"
    }}>{`Audio`}</h2>
    <Audio src="sounds/through_the_gate.mp3" name="Through the Gate" desc="Hundreds of years in the future, in a colonized Solar System, police detective Josephus Miller, born on Ceres in the asteroid belt, is sent to find a missing young woman, Juliette 'Julie' Andromeda Mao. James Holden, Executive Officer of the ice hauler Canterbury, is involved in a tragic incident that threatens to destabilize the uneasy peace between Earth, Mars and the Belt." mdxType="Audio" />
    <Audio src="../sounds/through_the_gate.mp3" mdxType="Audio" />
    <h2 {...{
      "id": "border-radius"
    }}>{`Border Radius`}</h2>
    <BorderRadius radii={theme.radii} mdxType="BorderRadius" />
    <h2 {...{
      "id": "color-row"
    }}>{`Color Row`}</h2>
    <ColorRow color="#000" name="Blackness" prefix="Dark - " mdxType="ColorRow" />
    <h2 {...{
      "id": "color-swatch"
    }}>{`Color Swatch`}</h2>
    <ColorSwatch color="#fff" mdxType="ColorSwatch" />
    <ColorSwatch color="#2d3748" name="Blueish" mdxType="ColorSwatch" />
    <ColorSwatch color="#667eea" name="Swift" mdxType="ColorSwatch" />
    <ColorSwatch color="#4fd1c5" name="4" prefix="Teal - " mdxType="ColorSwatch" />
    <h2 {...{
      "id": "download"
    }}>{`Download`}</h2>
    <Download name="Gatsby Themes Wallpaper" notes="Use for Social Media previews" src="../downloads/gatsby-themes-wallpaper.jpg" mdxType="Download" />
    <Download name="Logo" bg="black" src="../downloads/logo.png" mdxType="Download" />
    <h2 {...{
      "id": "font-family"
    }}>{`Font Family`}</h2>
    <FontFamily fonts={theme.fonts} mdxType="FontFamily" />
    <h2 {...{
      "id": "font-size"
    }}>{`Font Size`}</h2>
    <FontSize fontSizes={theme.fontSizes} mdxType="FontSize" />
    <h2 {...{
      "id": "font-weight"
    }}>{`Font Weight`}</h2>
    <FontWeight fontWeights={theme.fontWeights} mdxType="FontWeight" />
    <h2 {...{
      "id": "heading"
    }}>{`Heading`}</h2>
    <Heading styles={filterStyles({
      input: theme.styles,
      allowed: [`h1`, `h2`, `h3`, `h4`, `h5`, `h6`]
    })} theme={theme} mdxType="Heading" />
    <h2 {...{
      "id": "palette-color-rows"
    }}>{`Palette: Color Rows`}</h2>
    <Palette colors={normalizeThemeUIColors({
      colors: theme.colors.indigo
    })} prefix="Indigo - " mdxType="Palette" />
    <h2 {...{
      "id": "palette-color-swatches"
    }}>{`Palette: Color Swatches`}</h2>
    <Palette colors={normalizeThemeUIColors({
      colors: theme.colors
    })} single minimal mode="swatch" mdxType="Palette" />
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Table columns={[`120px 1fr`]} titles={[`Token`, `Value`]} className="sizes-table" mdxType="Table">
  {Object.entries(theme.sizes).map(([key, value]) => <div key={key}>
      <div>{key}</div>
      <div>{value}</div>
    </div>)}
    </Table>
    <h2 {...{
      "id": "shadows"
    }}>{`Shadows`}</h2>
    <Shadow shadows={theme.shadows} mdxType="Shadow" />
    <h2 {...{
      "id": "space"
    }}>{`Space`}</h2>
    <Space space={theme.space} mdxType="Space" />
    <h2 {...{
      "id": "video"
    }}>{`Video`}</h2>
    <Video name="LekoArts Intro" src="../videos/intro.mp4" poster="../videos/intro_poster.jpg" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      